class FarloUiRevolverCarousel {
	constructor(element, _config) {
		this.element = element;
		this.elementClass = 'site-farlo-ui-revolver-carousel';
		this.revolverViewport = element.querySelector('.site-farlo-ui-revolver-carousel__viewport');
		this.revolverContainer = element.querySelector('.site-farlo-ui-revolver-carousel__items');
		this.items = Array.from(this.revolverContainer.children);
		this.options = {
			accessible: true,
			autoplay: false,
			autoplaySpeed: 3000,
			controls: true,
			countItems: false,
			draggable: true,
			fader: false,
			fullWidth: false,
			infinite: true,
			pagination: true,
			itemsInView: 1,
			slideByItemsInView: true,
			touch: true,
			..._config
		};
		this.currentItem = 0;
		this.isAnimating = false;
		this.itemCount = 0;
		this.divisor = 1;
		this.pagination = null;
		this.controls = null;
		this.autoplayInterval = null;
		this.handleWindowResize = this.handleWindowResize.bind(this);
		this.handleKeyDown = this.handleKeyDown.bind(this);

		this.init();
	}

	init() {
		this.setUpRevolver();
		this.setUpRevolverSizes();

		if (this.options.autoplay) {
			this.startAutoplay();
		}

		if (this.options.controls) {
			this.setUpControls();
		}

		if (this.options.pagination) {
			this.setUpPagination();
		}

		if (this.options.fullWidth) {
			this.setUpFullWidth();
		}

		if (this.options.touch) {
			this.setupTouch();
		}

		if (this.options.draggable) {
			this.setUpMouseEvents();
		}

		window.addEventListener('resize', this.handleWindowResize);

		if (this.options.accessible) {
			this.revolverViewport.addEventListener('keydown', this.handleKeyDown);

			// Add ARIA attributes
			this.revolverViewport.setAttribute('role', 'region');
			this.revolverViewport.setAttribute('aria-label', 'Carousel');
			this.revolverViewport.setAttribute('aria-live', 'polite');
			this.revolverContainer.setAttribute('role', 'list');
			this.items.forEach((item) => item.setAttribute('role', 'listitem'));
		}
	}

	handleWindowResize() {
		// Update the carousel sizes on window resize
		this.setUpRevolverSizes();
	}

	setUpControls() {
		const controls = document.createElement('div');
		controls.className = this.elementClass + '__controls';

		const prevArrow = document.createElement('button');
		prevArrow.classList.add(
			this.elementClass + '__controls-arrow',
			this.elementClass + '__controls-arrow--prev'
		);
		prevArrow.innerHTML = '&#10094;';
		prevArrow.addEventListener('click', (e) => {
			e.stopImmediatePropagation();
			this.updateRevolverItem(this.currentItem - 1);
		});

		const nextArrow = document.createElement('button');
		nextArrow.classList.add(
			this.elementClass + '__controls-arrow',
			this.elementClass + '__controls-arrow--next'
		);
		nextArrow.innerHTML = '&#10095;';
		nextArrow.addEventListener('click', (e) => {
			e.stopImmediatePropagation();
			this.updateRevolverItem(this.currentItem + 1);
		});

		controls.appendChild(prevArrow);
		controls.appendChild(nextArrow);

		this.revolverViewport.appendChild(controls);

		this.controls = controls;
	}

	setUpFullWidth() {
		this.revolverViewport.classList.add(this.elementClass + '__viewport--full-width');
	}

	setUpPagination() {
		const pager = document.createElement('div');
		pager.className = this.elementClass + '__pagination';

		this.revolverViewport.appendChild(pager);

		this.pagination = pager;

		this.updatePaginationDots();
	}

	updatePaginationDots() {
		this.pagination.innerHTML = '';

		let numPaginationItems = this.itemCount;
		if (this.options.slideByItemsInView) {
			numPaginationItems = Math.ceil(this.itemCount / this.options.itemsInView);
		}

		for (let i = 0; i < numPaginationItems; i += 1) {
			const dot = document.createElement('span');
			dot.classList.add(this.elementClass + '__pagination-dot');
			if (i === this.currentItem) {
				dot.classList.add('site-farlo-ui-revolver-carousel__pagination-dot--active');
			}
			dot.setAttribute('tabindex', '0');
			dot.setAttribute('role', 'tab');
			dot.setAttribute('aria-label', i.toString());

			dot.addEventListener('click', () => {
				this.updateRevolverItem(i);
			});

			this.pagination.appendChild(dot);
		}
	}

	setUpRevolver() {
		this.itemCount = this.items.length / this.divisor;
	}

	setUpRevolverSizes() {
		const containerWidth = this.revolverViewport.clientWidth;
		const itemWidth = containerWidth / this.options.itemsInView;
		const totalItemsInView = this.options.slideByItemsInView ? this.options.itemsInView : 1;

		for (let i = 0; i < this.itemCount; i += 1) {
			const item = this.items[ i ];
			item.style.width = `${ itemWidth }px`;

			if (i >= this.currentItem && i < this.currentItem + totalItemsInView) {
				item.classList.add('site-farlo-ui-revolver-carousel__item--active');
			} else {
				item.classList.remove('site-farlo-ui-revolver-carousel__item--active');
			}
		}

		const visibleContainerWidth = itemWidth * this.itemCount;
		const translateX = -this.currentItem * (100 / this.itemCount);

		this.revolverContainer.style.transform = `translateX(${ translateX }%)`;
		this.revolverContainer.style.width = `${ visibleContainerWidth }px`;
	}

	startAutoplay() {
		if (this.autoplayInterval) {
			// Autoplay is already running
			return;
		}

		this.autoplayInterval = setInterval(() => {
			this.updateRevolverItem(this.currentItem + 1);
		}, this.options.autoplaySpeed); // Adjust the interval duration as needed
	}

	stopAutoplay() {
		clearInterval(this.autoplayInterval);
		this.autoplayInterval = null;
	}

	setupTouch() {
		let touchStartX = 0;
		let touchEndX = 0;

		this.element.addEventListener('touchstart', (e) => {
			touchStartX = e.touches[ 0 ].clientX;
			this.stopAutoplay();
		});

		this.element.addEventListener('touchend', (e) => {
			touchEndX = e.changedTouches[ 0 ].clientX;
			const touchDistance = touchEndX - touchStartX;

			if (touchDistance > 0) {
				this.updateRevolverItem(this.currentItem - 1);
			} else if (touchDistance < 0) {
				this.updateRevolverItem(this.currentItem + 1);
			}
		});
	}

	setUpMouseEvents() {
		// Variables for tracking mouse drag
		let isDragging = false;
		let dragStartX = 0;
		const dragStartTranslateX = this.getCurrentTranslateX();
		const dragThresholdRatio = 0.25; // Adjust this value as needed
		let dragThreshold = this.revolverContainer.clientWidth * dragThresholdRatio;

		// Add event listeners for mouse events
		this.revolverViewport.addEventListener('mousedown', (e) => {
			const targetElement = e.target;
			const targetClicked = targetElement.closest('.site-farlo-ui-revolver-carousel__item');
			if (!targetClicked) {
				return;
			}

			e.preventDefault(); // Prevent text selection on mousedown
			isDragging = true;
			dragStartX = e.clientX;
			this.revolverViewport.style.cursor = 'grabbing';
		});

		this.revolverViewport.addEventListener('mousemove', (e) => {
			if (isDragging) {
				e.preventDefault(); // Prevent text selection on mousemove
				const dragDistance = e.clientX - dragStartX;
				const dragTranslateX = dragStartTranslateX + (dragDistance / this.revolverContainer.offsetWidth) * 100;
				this.revolverContainer.style.transform = `translateX(${ dragTranslateX }%)`;
			}
		});

		document.addEventListener('mouseup', (e) => {
			if (isDragging) {
				e.preventDefault(); // Prevent text selection on mouseup
				const dragDistance = e.clientX - dragStartX;
				dragThreshold = this.options.dragThreshold || 20;

				if (dragDistance > dragThreshold) {
					this.updateRevolverItem(this.currentItem - 1);
				} else if (dragDistance < -dragThreshold) {
					this.updateRevolverItem(this.currentItem + 1);
				} else {
					this.updateRevolverItem(this.currentItem);
				}

				isDragging = false;
				this.revolverViewport.style.cursor = 'grab';
			}
		});

		this.revolverViewport.addEventListener('mouseleave', () => {
			if (isDragging) {
				isDragging = false;
				this.revolverViewport.style.cursor = 'grab';
			}
		});
	}

	getCurrentTranslateX() {
		const transformMatrix = window.getComputedStyle(this.revolverContainer).getPropertyValue('transform');
		const matrixValues = transformMatrix.split('(')[ 1 ].split(')')[ 0 ].split(',');
		const translateX = parseInt(matrixValues[ 4 ].trim());
		return translateX;
	}

	handleKeyDown(event) {
		switch (event.key) {
			case 'ArrowLeft' || 'ArrowUp' :
				event.preventDefault();
				this.updateRevolverItem(this.currentItem - 1);
				break;
			case 'ArrowRight' || 'ArrowDown' :
				event.preventDefault();
				this.updateRevolverItem(this.currentItem + 1);
				break;
			case 'Home' :
				event.preventDefault();
				this.updateRevolverItem(0);
				break;
			case 'End' :
				event.preventDefault();
				this.updateRevolverItem(this.itemCount - 1);
				break;
		}
	}

	updateRevolverItem(index) {
		if (this.isAnimating) {
			return;
		}
		let startIndex;
		let endIndex;

		// Clear the autoplay interval if it's running
		if (this.autoplayInterval) {
			clearInterval(this.autoplayInterval);
			this.autoplayInterval = null;
		}

		if (this.options.slideByItemsInView) {
			const slidesInView = Math.ceil(this.itemCount / this.options.itemsInView);
			const adjustedIndex = index % slidesInView;

			if (adjustedIndex < 0) {
				startIndex = (slidesInView + adjustedIndex) * this.options.itemsInView;
			} else {
				startIndex = adjustedIndex * this.options.itemsInView;
			}

			if (index < 0) {
				if (!this.options.infinite) {
					index = 0;
				} else {
					endIndex = Math.min(startIndex + this.options.itemsInView - 1, this.itemCount - 1);
				}
			} else if (index >= this.itemCount) {
				if (!this.options.infinite) {
					endIndex = Math.min(startIndex + this.options.itemsInView - 1, this.itemCount - 1);
				} else {
					index = 0;
				}
			}
		} else {
			if (index < 0) {
				if (!this.options.infinite) {
					index = 0;
				} else {
					index = this.itemCount - 1;
				}
			} else if (index >= this.itemCount) {
				if (!this.options.infinite) {
					index = this.itemCount - 1;
				} else {
					index = 0;
				}
			}

			startIndex = index;
			endIndex = index;
		}

		const translateX = -startIndex * (100 / this.itemCount);

		this.revolverContainer.style.transform = `translateX(${ translateX }%)`;
		this.isAnimating = true;

		setTimeout(() => {
			for (let i = 0; i < this.items.length; i ++) {
				const item = this.items[ i ];

				if (this.options.slideByItemsInView) {
					if (i >= startIndex && i <= endIndex) {
						item.classList.add('site-farlo-ui-revolver-carousel__item--active');
					} else {
						item.classList.remove('site-farlo-ui-revolver-carousel__item--active');
					}
				} else if (i === index) {
					item.classList.add('site-farlo-ui-revolver-carousel__item--active');
				} else {
					item.classList.remove('site-farlo-ui-revolver-carousel__item--active');
				}
			}

			const prevDot = this.pagination.querySelector('.site-farlo-ui-revolver-carousel__pagination-dot--active');
			if (prevDot) {
				prevDot.classList.remove('site-farlo-ui-revolver-carousel__pagination-dot--active');
			}

			let dotIndex;

			if (this.options.slideByItemsInView) {
				dotIndex = Math.floor(startIndex / this.options.itemsInView);
			} else {
				dotIndex = index;
			}

			const newDot = this.pagination.children[ dotIndex ];

			if (newDot) {
				newDot.classList.add('site-farlo-ui-revolver-carousel__pagination-dot--active');
			}

			this.revolverContainer.setAttribute('aria-activedescendant', `carousel-item-${index}`);
			const dots = this.pagination.querySelectorAll('.site-farlo-ui-revolver-carousel__pagination-dot');
			dots.forEach((dot, i) => {
				dot.setAttribute('aria-selected', i === index ? 'true' : 'false');
			});

			this.currentItem = index;
			this.isAnimating = false;

			// Restart autoplay if enabled
			if (this.options.autoplay) {
				this.startAutoplay();
			}
		}, 100);
	}
}


export default FarloUiRevolverCarousel;