export function farloUiReviewCarousel(element) {
    let flkty = "";
        flkty = new Flickity(element, {
            cellAlign: 'center',
            pageDots: false,
            prevNextButtons: true,
            wrapAround: true,
            groupCells: false,
            freeScroll: false,
            draggable: true,
            dragThreshold: 1,
            autoPlay:4000,
        });




}
